import { render, staticRenderFns } from "./MyMusic.vue?vue&type=template&id=429ca440&scoped=true&"
import script from "./MyMusic.vue?vue&type=script&lang=js&"
export * from "./MyMusic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "429ca440",
  null
  
)

export default component.exports